import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://img.alicdn.com/imgextra/i2/O1CN01LuqJvw25MY3tzh6vS_!!6000000007512-2-tps-2000-2000.png" className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
